
// This file is generated with scripts/i18n.js

import translationca from "./ca/translation.json";
import translationda from "./da/translation.json";
import translationde from "./de/translation.json";
import translationen from "./en/translation.json";
import translationes from "./es/translation.json";
import translationfi from "./fi/translation.json";
import translationfil from "./fil/translation.json";
import translationfr from "./fr/translation.json";
import translationga from "./ga/translation.json";
import translationid from "./id/translation.json";
import translationit from "./it/translation.json";
import translationja from "./ja/translation.json";
import translationko from "./ko/translation.json";
import translationnl from "./nl/translation.json";
import translationpl from "./pl/translation.json";
import translationptbr from "./pt-br/translation.json";
import translationro from "./ro/translation.json";
import translationru from "./ru/translation.json";
import translationsv from "./sv/translation.json";
import translationzhCN from "./zh-CN/translation.json";
import translationzhHK from "./zh-HK/translation.json";
import translationzhTW from "./zh-TW/translation.json";

const list = JSON.parse('[["ca","Català"],["da","Dansk"],["de","Deutsch"],["en","English"],["es","Español"],["fi","Suomi"],["fil","Filipino"],["fr","Français"],["ga","Gaeilge"],["id","Bahasa Indonesia"],["it","Italiano"],["ja","日本語"],["ko","한국어"],["nl","Nederlands"],["pl","Polski"],["pt-br","Português (Brasil)"],["ro","Română"],["ru","Русский"],["sv","Svenska"],["zh-CN","中文 (中国)"],["zh-HK","中文 (香港)"],["zh-TW","中文 (臺灣)"]]');

const translations = {
'ca': {translations: translationca},
'da': {translations: translationda},
'de': {translations: translationde},
'en': {translations: translationen},
'es': {translations: translationes},
'fi': {translations: translationfi},
'fil': {translations: translationfil},
'fr': {translations: translationfr},
'ga': {translations: translationga},
'id': {translations: translationid},
'it': {translations: translationit},
'ja': {translations: translationja},
'ko': {translations: translationko},
'nl': {translations: translationnl},
'pl': {translations: translationpl},
'pt-br': {translations: translationptbr},
'ro': {translations: translationro},
'ru': {translations: translationru},
'sv': {translations: translationsv},
'zh-CN': {translations: translationzhCN},
'zh-HK': {translations: translationzhHK},
'zh-TW': {translations: translationzhTW},
};

export { translations, list };
