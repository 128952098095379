import { useEffect, useMemo, useState } from "react";
// import { useLocalStorage } from "react-use";
const baseUrl = "https://jsonbin.org/sandysandy/turnips";

const useFilters = (selectedIsland, canUpdate) => {
  // const [filters, saveFilters] = useLocalStorage("filters", []);
  const [filters, saveFilters] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  // Array of strings
  const inputFilters = useMemo(
    () =>
      Array.isArray(filters) &&
      Array.from({ length: 13 }).map((v, i) =>
        String(Number(filters[i]) || "")
      ),
    [filters]
  );

  // Array of numbers
  const sanitizedFilters = useMemo(
    () =>
      Array.isArray(filters) &&
      Array.from({ length: 13 }).map((v, i) => Number(filters[i]) || undefined),
    [filters]
  );

  useEffect(() => {
    setLoading(true);
    const url = `${baseUrl}/${selectedIsland}`;
    const options = {
      method: "get",
      headers: {
        authorization: "token be80f61c-02ed-451d-8435-b2cb810b423d",
      },
    };

    if (Array.isArray(filters) && canUpdate) {
      options.method = "post";
      options.body = JSON.stringify(filters);
      options.headers = {
        "Content-Type": "application/json",
        authorization: "token be80f61c-02ed-451d-8435-b2cb810b423d",
      };
    }

    const fetchData = async () => {
      try {
        const res = await fetch(url, options);
        const json = await res.json();
        JSON.stringify(json) !== JSON.stringify(filters) && saveFilters(json);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [filters, saveFilters, selectedIsland, canUpdate]);

  return {
    filters: sanitizedFilters,
    inputFilters,
    saveFilters,
    error,
    loading,
  };
};

export default useFilters;
