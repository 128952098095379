import React from "react";
import { Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";

const Title = ({ island }) => {
  return (
    <Box mt={4} mb={2}>
      <Typography variant="h3" color="textPrimary">
        &quot;Stalk&quot; Market Predictor ({island})
      </Typography>
    </Box>
  );
};

Title.propTypes = {
  island: PropTypes.string.isRequired,
};

export default Title;
