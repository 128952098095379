import React, { useState } from "react";
import { Container, Box } from "@material-ui/core";
import { useFilters, useTitle, useShare, useCalculation } from "../utils";
import { Title, Filter, Footer } from "../containers";
import { ShareDialog, Chart, Table } from "../components";
import TabPanel from "./TabPanel";
import CustomizedSnackbars from "../components/CustomizedSnackbars";
import LinearProgress from "@material-ui/core/LinearProgress";
const islands = ["Toe Bean", "Cullen"];

const islandInUrl =
  new URLSearchParams(window.location.search).get("island") || "Toe Bean";

const defaultIsland =
  islands.indexOf(islandInUrl) !== -1 ? islandInUrl : islands[0];

const App = () => {
  useTitle();
  const [selectedIsland, setSelectedIsland] = useState(defaultIsland);
  const canUpdate = selectedIsland === defaultIsland;
  const { inputFilters, filters, saveFilters, error, loading } = useFilters(
    selectedIsland,
    canUpdate
  );

  const {
    onCloseShareModal,
    showShareDialog,
    openShareDialog,
    shareFilters,
  } = useShare(filters);

  const result = useCalculation({ filters });

  const selectedIslandIndex =
    islands.indexOf(selectedIsland) !== -1
      ? islands.indexOf(selectedIsland)
      : 0;

  const handleChangeTab = (tab) => {
    saveFilters(null);
    setSelectedIsland(tab);
  };

  return (
    <React.Fragment>
      <TabPanel
        onChange={handleChangeTab}
        selectedIslandIndex={selectedIslandIndex}
        islands={islands}
      />
      <Container maxWidth="md">
        <Title island={selectedIsland} />
        {loading && <LinearProgress style={{ margin: 10 }} />}
        {error && <CustomizedSnackbars />}
        <Box mx={[-1.5, 0]}>
          {canUpdate && (
            <Filter
              filters={inputFilters || []}
              onChange={saveFilters}
              openShareDialog={openShareDialog}
            />
          )}
          {result.filters && (
            <React.Fragment>
              <Chart {...result} />
              <Table {...result} />
            </React.Fragment>
          )}
          <Footer />
        </Box>
      </Container>
      <ShareDialog
        open={showShareDialog}
        filters={shareFilters}
        onClose={onCloseShareModal}
      />
    </React.Fragment>
  );
};

export default App;
