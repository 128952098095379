import { Box, Link as MaterialLink, Typography } from "@material-ui/core";
import { node, string } from "prop-types";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { QuantileRange } from "../components";

const Link = ({ href, children }) => (
  <MaterialLink href={href} target="_blank" rel="noopener">
    {children}
  </MaterialLink>
);
Link.propTypes = {
  href: string,
  children: node,
};
Link.defaults = {
  href: undefined,
};

const Footer = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return (
      <Box
        my={4}
        px={4}
        py={2}
        color="bkgs.mainAlt"
        bgcolor="bkgs.contentAlt"
        borderRadius={16}
      >
        <Box my={2}>
          <Typography variant="h5">{t("Usage")}</Typography>
          <Typography variant="body1">
            <Trans i18nKey="buyPriceInfo">
              - The <b>Buy Price</b> value is for your own island. It
              doesn&#39;t matter if you buy it in other island!
            </Trans>
            <br />
            <Trans i18nKey="priceChangeInfo">
              - Prices change <b>twice a day</b>. Be sure to log them. (We save
              your data in your device).
            </Trans>
            <br />
            <Trans i18nKey="guaranteedMinInfo">
              - The <b>Week min</b> value is a guaranteed minimum price you will
              get this week. Wait at least for this price.
            </Trans>
            <br />
            <Trans i18nKey="mostLikelyInfo">
              - The <b>Most Likely</b> value range is where <QuantileRange /> of
              the turnip prices will fall.
            </Trans>
          </Typography>
        </Box>
        <Box my={2}>
          <Typography variant="h5">{t("About")}</Typography>
          <Typography variant="body1">
            Largely Stolen from https://ac-turnip.com/, only difference being
            that data saved on the cloud instead of on local device meaning it
            can be accessed and updated from multiple devices
          </Typography>
        </Box>
      </Box>
    );
  }, [t]);
};

export default Footer;
